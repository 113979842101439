import React from "react"
import qs from "query-string"
import { loadStripe } from "@stripe/stripe-js"
// import CheckoutForm from "./CheckoutForm";
// import "./App.css";
// Make sure to call loadStripe outside of a component’s render to avoid
// recreating the Stripe object on every render.
// loadStripe is initialized with your real test publishable API key.

export default function App() {
  function handleCheckoutClick(e) {
    loadStripe(
      "pk_test_51HlFaPKWbA8KCvgi3HKsJvh5JYwJ8y60OioW8Q9g48hpvk38TLAzzQCx4fVHfARs0Sf79BiO2NfN8HQ52Hxp6qeo00OePRoGOJ"
    ).then(stripe => {
      fetch(`https://madjon-website.netlify.app/.netlify/functions/payment`, {
        method: "POST",
        body: JSON.stringify({ price: qs.parse(window.location.search).price }),
      })
        .then(r => r.json())
        .then(session => stripe.redirectToCheckout({ sessionId: session.id }))
        .then(result => {
          if (result.error) {
            alert(result.error.message)
          }
        })
        .catch(error => {
          console.error("Error:", error)
        })
    })
  }

  return (
    <div className="App">
      <button onClick={handleCheckoutClick}>checkout</button>
    </div>
  )
}
